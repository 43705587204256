import { Tablist, SidebarTab, Paragraph, Button } from 'evergreen-ui'
import logo from '../../../assets/images/logo-home.png'

const Nav = (props) => {

  return (
    <div style={{minWidth: 175, position: 'fixed', backgroundColor: 'white', height: '100%'}}>
      <div style={{textAlign: 'center', marginTop: 16, marginBottom: 16, marginLeft: -16}}>
        <img style={{height: 50}} src={logo} alt="logo" />
        <Paragraph marginBottom={16}>{props.username}</Paragraph>
      </div>

      <Tablist marginBottom={16} flexBasis={240} marginRight={12} marginLeft={12} marginTop={12}>
        {props.tabs.map((tab, index) => (
          <SidebarTab
            key={tab}
            id={tab}
            onSelect={() => props.setSelectedIndex(index)}
            isSelected={index === props.selectedIndex}
            aria-controls={`panel-${tab}`}
          >
            {tab}
          </SidebarTab>
        ))}

        <Button marginTop={16} onClick={() => props.removeCookie('noazet-admin')}>Déconnexion</Button>
      </Tablist>
    </div>
  )
}

export default Nav