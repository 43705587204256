import { useState, useEffect } from 'react'
import token from '../../../../Token'
import { Pane, Dialog, Paragraph, Button, EditIcon, TrashIcon } from 'evergreen-ui'

const Page = (props) => {

    return (
        <Pane>
            <Dialog
                isShown={props.open[props.i]}
                hasFooter={false}
                title={props.page.title_fr || ''}
                onCloseComplete={() => props.setOpen({[props.i] : false})}
            >
                
                <Pane>
                    <Button marginBottom={25} marginRight={25} iconBefore={EditIcon}>
                        Modifier la page
                    </Button>
                    <Button marginBottom={25} iconBefore={TrashIcon} intent="danger">
                        Supprimer la page
                    </Button>

                    <br></br>

                    {props.page.image_path.includes('https') ? <img style={{height: 200, width: 200, marginBottom: 25}} alt='Chapter illustration' src={props.page.image_path}/> : <Paragraph>[{props.page.image_path}]</Paragraph>}
                    
                    {/* to do saut de lignes content */}
                    <Paragraph><b>ID</b> : {props.page.id}</Paragraph>
                    <Paragraph><b>Titre FR</b> : {props.page.title_fr}</Paragraph>
                    <Paragraph><b>Titre EN</b> : {props.page.title}</Paragraph>
                    <Paragraph><b>Contenu FR</b> : {props.page.content_fr}</Paragraph>
                    <Paragraph><b>Contenu EN</b> : {props.page.content}</Paragraph>
                    <Paragraph><b>Scrollable</b> : {props.needs_scroll ? 'Oui' : 'Non'}</Paragraph>
                    <Paragraph><b>Background bulles</b> : {props.has_background ? 'Oui' : 'Non'}</Paragraph>

                </Pane>
            </Dialog>
        </Pane>
    )
}

export default Page