import { useState, useEffect, useCallback, useRef } from 'react'
import token from '../../../../Token'
import { Pane, Dialog, TextInputField, Select, Paragraph, FileUploader, FileCard, Button, TrashIcon } from 'evergreen-ui'

const UpdateTheme = (props) => {
    const [nameFr, setNameFr] = useState(null)
    const [nameEn, setNameEn] = useState(null)

    const [isNameFrInvalid, setIsNameFrInvalid] = useState(true)
    const [initialNameFr, setInitialNameFr] = useState(true)

    const [index, setIndex] = useState(null)

    const [files, setFiles] = useState([])
    const [fileRejections, setFileRejections] = useState([])
    const handleChange = useCallback((files) => setFiles([files[0]]), [])
    const handleRejected = useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove = useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])

    const [confirmation, setConfirmation] = useState(false)

    const initialSelect = useRef(null)
    const [initialSelected, setIntitialSelected] = useState(false)

    useEffect(() => {
        if (!initialSelected)
            setTimeout(() => {
                initialSelect.current.focus()
                setIntitialSelected(true)
            }, 0)
    })

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    return (
        <Pane>
            <Dialog
                isShown={props.open}
                confirmLabel='Enregistrer'
                cancelLabel='Annuler'
                isConfirmDisabled={!nameFr && !nameEn && !files.length && (!index || (index && index === (props.theme.index + 1).toString()))}
                onConfirm={() => setConfirmation(true)}
                title={'Modifier le thème ' + props.theme.name_fr}
                onCloseComplete={() => props.setOpen(false)}
            >
                <div style={{height: 60, width: 60, backgroundColor: '#277C9E', borderRadius: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 25}}>
                    <img style={{height: props.theme.iconHeight, width: props.theme.iconWidth}} alt='Theme icon' src={props.theme.iconPath}/>
                </div>
                <Pane>
                    <TextInputField
                        ref={initialSelect}
                        placeholder={props.theme.name_fr}
                        onChange={(e) => {
                            if (initialNameFr)
                                setInitialNameFr(false)

                            let val = e.target.value

                            if (!val) 
                                setIsNameFrInvalid(true)
                            else
                                setIsNameFrInvalid(false)
            
                            setNameFr(val)
                        }}
                        isInvalid={!initialNameFr && isNameFrInvalid}
                        label="Nom (FR)*"
                        description="Le nom du thème dans la version française de l'app."
                    />

                    <TextInputField
                        placeholder={props.theme.name}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                        label="Nom (EN)"
                        description="Le nom du thème dans la version anglaise de l'app."
                        hint="Laisser vide pour garder le même titre qu'en français."
                    />

                    <Paragraph fontWeight={600}>Index</Paragraph>
                    <Paragraph fontSize={12} color='rgb(105, 111, 140)' marginBottom={10}>La place du thème dans la liste.</Paragraph>
                    <Select onChange={(e) => setIndex(e.target.value)} defaultValue={props.theme.index + 1} width={75}>
                        {props.themes.map((c, i) => (
                            <option key={i} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </Select>

                    <Pane marginTop={25} maxWidth={654}>
                        <FileUploader
                            label="Icône*"
                            description="Taille maximale 10 MB. Dimensions max : 60 x 60px."
                            maxSizeInBytes={10 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChange}
                            onRejected={handleRejected}
                            renderFile={(file) => {
                                const { name, size, type } = file
                                const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                const { message } = fileRejection || {}
                                return (
                                    <FileCard
                                        key={name}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemove}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                    />
                                )
                            }}
                            values={files}
                        />
                    </Pane>
                
                    <Button onClick={() => setDeleteConfirmation(true)} marginTop={30} iconBefore={TrashIcon} intent="danger">
                        Supprimer le thème
                    </Button>

                    <Pane>
                        <Dialog
                            isShown={confirmation}
                            title="Modifier le thème ?"
                            onCloseComplete={() => setConfirmation(false)}
                            confirmLabel="Confirmer"
                        >
                            Les modifications seront visibles immédiatement dans l'app. Continuer ?
                        </Dialog>
                    </Pane>

                    <Pane>
                        <Dialog
                            isShown={deleteConfirmation}
                            title="Supprimer le thème ?"
                            onCloseComplete={() => setDeleteConfirmation(false)}
                            confirmLabel="Confirmer"
                        >
                            Le thème va être définitivement supprimé. Continuer ?
                        </Dialog>
                    </Pane>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default UpdateTheme