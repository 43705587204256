import { useState, useEffect, useCallback, useRef } from 'react'
import token from '../../../../Token'
import { Pane, Dialog, TextInputField, TextareaField, FileUploader, FileCard, Switch, Paragraph } from 'evergreen-ui'

const NewPage = (props) => {
    const [nameFr, setTitleFr] = useState(null)
    const [nameEn, setTitleEn] = useState(null)
    const [contentFr, setContentFr] = useState(null)
    const [contentEn, setContentEn] = useState(null)

    const [isNameFrInvalid, setIsNameFrInvalid] = useState(true)
    const [initialNameFr, setInitialNameFr] = useState(true)

    const [files, setFiles] = useState([])
    const [fileRejections, setFileRejections] = useState([])
    const handleChange = useCallback((files) => setFiles([files[0]]), [])
    const handleRejected = useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove = useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])

    const [confirmation, setConfirmation] = useState(false)

    const initialSelect = useRef(null)
    const [initialSelected, setIntitialSelected] = useState(false)

    useEffect(() => {
        if (!initialSelected)
            setTimeout(() => {
                initialSelect.current.focus()
                setIntitialSelected(true)
            }, 0)
    })

    const [hasBackground, setHasBackground] = useState(false)
    const [isScrollable, setIsScrollable] = useState(false)

    return (
        <Pane>
            <Dialog
                isShown={props.open}
                confirmLabel='Créer'
                cancelLabel='Annuler'
                isConfirmDisabled={isNameFrInvalid || !contentEn || ! contentFr}
                onConfirm={() => setConfirmation(true)}
                title={'Nouvelle page dans le chapitre ' + props.chapter.name_fr} 
                onCloseComplete={() => props.setOpen(false)}
            >
                <Pane>
                    <TextInputField
                        ref={initialSelect}
                        onChange={(e) => {
                            if (initialNameFr)
                                setInitialNameFr(false)

                            let val = e.target.value

                            if (!val) 
                                setIsNameFrInvalid(true)
                            else
                                setIsNameFrInvalid(false)
            
                            setTitleFr(val)
                        }}
                        isInvalid={!initialNameFr && isNameFrInvalid}
                        label="Titre (FR)*"
                        description="Le titre de la page dans la version française de l'app."
                    />

                    <TextInputField
                        onChange={(e) => {
                            setTitleEn(e.target.value)
                        }}
                        label="Titre (EN)"
                        description="Le titre de la page dans la version anglaise de l'app."
                        hint="Laisser vide pour garder le même titre qu'en français."
                    />

                    <TextareaField
                        onChange={(e) => {
                            setContentFr(e.target.value)
                        }}
                        label="Contenu (FR)*"
                        description="Le contenu de la page dans la version française de l'app."
                    />

                    <TextareaField
                        onChange={(e) => {
                            setContentEn(e.target.value)
                        }}
                        label="Contenu (EN)*"
                        description="Le contenu de la page dans la version anglaise de l'app."
                    />

                    <Paragraph fontWeight={600} marginBottom={10}>Background (bulles) ?</Paragraph>
                    <Switch height={20} checked={hasBackground} onChange={(e) => setHasBackground(e.target.checked)}/>

                    <Paragraph fontWeight={600} marginTop={25} marginBottom={10}>Scrollable ?</Paragraph>
                    <Switch height={20} checked={isScrollable} onChange={(e) => setIsScrollable(e.target.checked)}/>

                    <Pane marginTop={25} maxWidth={654}>
                        <FileUploader
                            label="Illustration"
                            description="Taille maximale 30 MB. Dimensions max recommandées : 750 x 750px."
                            maxSizeInBytes={30 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChange}
                            onRejected={handleRejected}
                            renderFile={(file) => {
                                const { name, size, type } = file
                                const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                const { message } = fileRejection || {}
                                return (
                                    <FileCard
                                        key={name}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemove}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                    />
                                )
                            }}
                            values={files}
                        />
                    </Pane>
                
                    <Pane>
                        <Dialog
                            isShown={confirmation}
                            title="Créer la page ?"
                            onCloseComplete={() => setConfirmation(false)}
                            confirmLabel="Confirmer"
                        >
                            La page va être visible immédiatement dans l'app. Continuer ?
                        </Dialog>
                    </Pane>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default NewPage