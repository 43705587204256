import { useState, useEffect, useCallback, useRef } from 'react'
import token from '../../../../Token'
import { Pane, Dialog, TextInputField, Select, Paragraph, FileUploader, FileCard } from 'evergreen-ui'

const NewTheme = (props) => {
    const [nameFr, setNameFr] = useState(null)
    const [nameEn, setNameEn] = useState(null)

    const [isNameFrInvalid, setIsNameFrInvalid] = useState(true)
    const [initialNameFr, setInitialNameFr] = useState(true)

    const [files, setFiles] = useState([])
    const [fileRejections, setFileRejections] = useState([])
    const handleChange = useCallback((files) => setFiles([files[0]]), [])
    const handleRejected = useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove = useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])

    const [confirmation, setConfirmation] = useState(false)

    const initialSelect = useRef(null)
    const [initialSelected, setIntitialSelected] = useState(false)

    useEffect(() => {
        if (!initialSelected)
            setTimeout(() => {
                initialSelect.current.focus()
                setIntitialSelected(true)
            }, 0)
    })

    return (
        <Pane>
            <Dialog
                isShown={props.open}
                confirmLabel='Créer'
                cancelLabel='Annuler'
                isConfirmDisabled={isNameFrInvalid || !files.length}
                onConfirm={() => setConfirmation(true)}
                title='Nouveau thème'
                onCloseComplete={() => props.setOpen(false)}
            >
                <Pane>
                    <TextInputField
                        ref={initialSelect}
                        onChange={(e) => {
                            if (initialNameFr)
                                setInitialNameFr(false)

                            let val = e.target.value

                            if (!val) 
                                setIsNameFrInvalid(true)
                            else
                                setIsNameFrInvalid(false)
            
                            setNameFr(val)
                        }}
                        isInvalid={!initialNameFr && isNameFrInvalid}
                        label="Nom (FR)*"
                        description="Le nom du thème dans la version française de l'app."
                    />

                    <TextInputField
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                        label="Nom (EN)"
                        description="Le nom du thème dans la version anglaise de l'app."
                        hint="Laisser vide pour garder le même titre qu'en français."
                    />

                    <Paragraph fontWeight={600}>Index</Paragraph>
                    <Paragraph fontSize={12} color='rgb(105, 111, 140)' marginBottom={10}>La place du nouveau thème dans la liste.</Paragraph>
                    <Select defaultValue={props.themes.length + 1} width={75}>
                        {[...props.themes, {}].map((c, i) => (
                            <option key={i} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </Select>

                    <Pane marginTop={25} maxWidth={654}>
                        <FileUploader
                            label="Icône*"
                            description="Taille maximale 10 MB. Dimensions max : 60 x 60px."
                            maxSizeInBytes={10 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChange}
                            onRejected={handleRejected}
                            renderFile={(file) => {
                                const { name, size, type } = file
                                const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                const { message } = fileRejection || {}
                                return (
                                    <FileCard
                                        key={name}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemove}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                    />
                                )
                            }}
                            values={files}
                        />
                    </Pane>
                
                    <Pane>
                        <Dialog
                            isShown={confirmation}
                            title="Créer le thème ?"
                            onCloseComplete={() => setConfirmation(false)}
                            confirmLabel="Confirmer"
                        >
                            Le thème va être visible immédiatement dans l'app. Continuer ?
                        </Dialog>
                    </Pane>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default NewTheme