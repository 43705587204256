import { useState, useEffect } from 'react'
import token from '../../../../../Token'
import { Pane, Dialog, Paragraph, Button, EditIcon, TrashIcon } from 'evergreen-ui'

const Quiz = (props) => {

    return (
        <Pane>
            <Dialog
                isShown={props.open[props.i]}
                hasFooter={false}
                title={props.question.question_fr || ''}
                onCloseComplete={() => props.setOpen({[props.i] : false})}
            >
                
                <Pane>
                    <Button marginBottom={25} marginRight={25} iconBefore={EditIcon}>
                        Modifier le quiz
                    </Button>
                    <Button marginBottom={25} iconBefore={TrashIcon} intent="danger">
                        Supprimer le quiz
                    </Button>

                    <br></br>

                    {props.quiz.image_path && props.quiz.image_path.includes('https') ? <img style={{height: 200, width: 200, marginBottom: 25}} alt='Quiz illustration' src={props.quiz.image_path}/> : <></>}
                    
                    <Paragraph><b>ID</b> : {props.question.id}</Paragraph>
                    <Paragraph><b>Question FR</b> : {props.question.question_fr}</Paragraph>
                    <Paragraph><b>Question EN</b> : {props.question.question}</Paragraph>
                    <Paragraph><b>Réponse 1 FR</b> : {props.question.first_answer_fr}</Paragraph>
                    <Paragraph><b>Réponse 1 EN</b> : {props.question.first_answer}</Paragraph>
                    <Paragraph><b>Réponse 2 FR</b> : {props.question.second_answer_fr}</Paragraph>
                    <Paragraph><b>Réponse 2 EN</b> : {props.question.second_answer}</Paragraph>
                    <Paragraph><b>Réponse 3 FR</b> : {props.question.third_answer_fr}</Paragraph>
                    <Paragraph><b>Réponse 3 EN</b> : {props.question.third_answer}</Paragraph>
                    <Paragraph><b>Réponse 4 FR</b> : {props.question.fourth_answer_fr}</Paragraph>
                    <Paragraph><b>Réponse 4 EN</b> : {props.question.fourth_answer}</Paragraph>
                    <Paragraph><b>Réponse 5 FR</b> : {props.question.fifth_answer_fr}</Paragraph>
                    <Paragraph><b>Réponse 5 EN</b> : {props.question.fifth_answer}</Paragraph>
                    <Paragraph><b>Réponse 6 FR</b> : {props.question.sixth_answer_fr}</Paragraph>
                    <Paragraph><b>Réponse 6 EN</b> : {props.question.sixth_answer}</Paragraph>
                    <Paragraph><b>Réponse(s)</b> : {props.question.answers}</Paragraph>
                    <Paragraph><b>Explication réponse 1 FR</b> : {props.question.answer_description_fr}</Paragraph>
                    <Paragraph><b>Explication réponse 1 EN</b> : {props.question.answer_description}</Paragraph>
                    <Paragraph><b>Explication réponse 2 FR</b> : {props.question.answer_description2_fr}</Paragraph>
                    <Paragraph><b>Explication réponse 2 EN</b> : {props.question.answer_description2}</Paragraph>
                    <Paragraph><b>Explication réponse 3 FR</b> : {props.question.answer_description3_fr}</Paragraph>
                    <Paragraph><b>Explication réponse 3 EN</b> : {props.question.answer_description3}</Paragraph>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default Quiz