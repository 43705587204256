import React, { useState, useEffect } from 'react'
import token from '../../../Token'
import { Table, TextInput, Spinner } from 'evergreen-ui'
import moment from 'moment'
import User from './User'

const getUsers = async () => {
    try {
        let req = await fetch('https://api.noazet.com/v1/users', {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return
        }

        for (let i = 0; i < res.users.length; i++) {
            
            switch (res.users[i].objective) {
                case 1:
                    res.users[i].objective = "Découvrir la crypto"
                    break
                case 2:
                    res.users[i].objective = "Découvrir l'investissement"
                    break
                case 3:
                    res.users[i].objective = "Découvrir le trading"
                    break
                case 4:
                    res.users[i].objective = "Découvrir l'économie"
                    break
                case 5:
                    res.users[i].objective = "Tout découvrir"
                    break
                default:
                    break
            }

            switch (res.users[i].experience) {
                case 1:
                    res.users[i].experience = "Débutant"
                    break
                case 2:
                    res.users[i].experience = "Intermédiaire"
                    break
                case 3:
                    res.users[i].experience = "Expert"
                    break
                default:
                    break
            }

            res.users[i].createdAt = moment(res.users[i].createdAt).toLocaleString()
            res.users[i].updatedAt = moment(res.users[i].updatedAt).toLocaleString()

            if (res.users[i].isEmailVerified)
                res.users[i].isEmailVerified = 'Oui'
            else
                res.users[i].isEmailVerified = 'Non'

            if (res.users[i].premiumEndDate === '0001-01-01T00:00:00Z')
                res.users[i].premiumEndDate = '/'
            else
                res.users[i].premiumEndDate = moment(res.users[i].premiumEndDate).toLocaleString()

            let cursor = res.users[i].gameTime
            let hours = Math.floor(cursor / 3600)
            cursor -= hours * 3600
            let minutes = Math.floor(cursor / 60)
            cursor -= minutes * 60
            let seconds = Math.floor(cursor / 60)
            cursor -= seconds * 60
            res.users[i].gameTime = hours + ' heure(s) ' + minutes + ' minute(s) ' + seconds + ' seconde(s)'

            
            if (!res.users[i].referer)
                res.users[i].referer = '/'
        }

        return res.users
        
    } catch (e) {
        console.log(e)
    }
}

const Users = (props) => {
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searching, setSearching] = useState(false)

    useEffect(() => {
        const fetchUsers = async () => {
            let users = await getUsers()
            setUsers(users)
        }
        if (!users.length)
            fetchUsers()
    }, [users.length])

    const [finalUsers, setFinalUsers] = useState([])

    useEffect(() => {
        if (searching)
            setFinalUsers(filteredUsers)
        else
            setFinalUsers(users)
    }, [filteredUsers, searching, users])

    const [open, setOpen] = useState({})

    return (
        <>
        <TextInput onChange={(e) => {
            let t = e.target.value

            if (t)
                setSearching(true)
            else
                setSearching(false)

            if (users.length) {
                let filteredUsers = []
                
                for (let i = 0; i < users.length; i++) {
                    if (users[i].id.includes(t) || users[i].username.includes(t) || users[i].email.includes(t)) {
                        filteredUsers.push(users[i])
                    }
                }

                setFilteredUsers(filteredUsers)
            }
        }} name="search" placeholder="Rechercher..." marginTop={25}/>

        <Table marginTop={25}>
            <Table.Head>
                <Table.TextHeaderCell>ID</Table.TextHeaderCell>
                <Table.TextHeaderCell>Nom d'utilisateur</Table.TextHeaderCell>
                <Table.TextHeaderCell>Adresse e-mail</Table.TextHeaderCell>
                <Table.TextHeaderCell>Objectif</Table.TextHeaderCell>
                <Table.TextHeaderCell>Expérience</Table.TextHeaderCell>
                <Table.TextHeaderCell>Balance (NZT)</Table.TextHeaderCell>
                <Table.TextHeaderCell>Date d'inscription</Table.TextHeaderCell>
                <Table.TextHeaderCell>Dernière activité</Table.TextHeaderCell>
                <Table.TextHeaderCell>Adresse e-mail vérifiée</Table.TextHeaderCell>
                <Table.TextHeaderCell>Dernière adresse IP</Table.TextHeaderCell>
                <Table.TextHeaderCell>Date de fin d'abonnement</Table.TextHeaderCell>
                <Table.TextHeaderCell>XP</Table.TextHeaderCell>
                <Table.TextHeaderCell>Dernier streak de connexion</Table.TextHeaderCell>
                <Table.TextHeaderCell>Temps de jeu</Table.TextHeaderCell>
                <Table.TextHeaderCell>Streaks de 7 jours</Table.TextHeaderCell>
                <Table.TextHeaderCell>Parrain</Table.TextHeaderCell>
                <Table.TextHeaderCell>Nombre de filleuls</Table.TextHeaderCell>
                <Table.TextHeaderCell>Record de streak de connexion</Table.TextHeaderCell>
                <Table.TextHeaderCell>Chapitres gratuits restants</Table.TextHeaderCell>
                <Table.TextHeaderCell>Code de parrainage</Table.TextHeaderCell>
            </Table.Head>

            {!finalUsers.length && !searching ? <Spinner marginTop={25} marginBottom={25} marginLeft='calc(50vw - 191px)'/> : <></>}
            
            <Table.Body >
                {finalUsers.map((user, i) => (
                    <React.Fragment key={i}>
                        <Table.Row key={user.id} isSelectable onSelect={() => setOpen({
                            [i]: true
                        })}>
                            <Table.TextCell>{user.id}</Table.TextCell>
                            <Table.TextCell>{user.username}</Table.TextCell>
                            <Table.TextCell>{user.email}</Table.TextCell>
                            <Table.TextCell>{user.objective}</Table.TextCell>
                            <Table.TextCell>{user.experience}</Table.TextCell>
                            <Table.TextCell isNumber>{user.balance}</Table.TextCell>
                            <Table.TextCell>{user.createdAt}</Table.TextCell>
                            <Table.TextCell>{user.updatedAt}</Table.TextCell>
                            <Table.TextCell>{user.isEmailVerified}</Table.TextCell>
                            <Table.TextCell>{user.lastIp}</Table.TextCell>
                            <Table.TextCell>{user.premiumEndDate}</Table.TextCell>
                            <Table.TextCell isNumber>{user.xp}</Table.TextCell>
                            <Table.TextCell isNumber>{user.streak}</Table.TextCell>
                            <Table.TextCell isNumber>{user.gameTime}</Table.TextCell>
                            <Table.TextCell isNumber>{user.streak7_count}</Table.TextCell>
                            <Table.TextCell>{user.referer}</Table.TextCell>
                            <Table.TextCell isNumber>{user.referees}</Table.TextCell>
                            <Table.TextCell isNumber>{user.streak_record}</Table.TextCell>
                            <Table.TextCell isNumber>{user.freeChapsLeft}</Table.TextCell>
                            <Table.TextCell>{user.refCode}</Table.TextCell>
                        </Table.Row> 

                        {open[i] ? <User users={users} setUsers={setUsers} user={user} open={open} setOpen={setOpen} i={i}/> : <></>}
                    </React.Fragment>
                ))}
            </Table.Body>
        </Table>
        <br/>
        </>
    )
}

export default Users
