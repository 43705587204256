import { useState, useEffect, useCallback, useRef } from 'react'
import token from '../../../../Token'
import { Pane, Dialog, TextInputField, Select, Paragraph, FileUploader, FileCard, Button, TrashIcon } from 'evergreen-ui'

const UpdateChapter = (props) => {
    const [nameFr, setNameFr] = useState(null)
    const [nameEn, setNameEn] = useState(null)

    const [isNameFrInvalid, setIsNameFrInvalid] = useState(true)
    const [initialNameFr, setInitialNameFr] = useState(true)

    const [index, setIndex] = useState(null)

    const [confirmation, setConfirmation] = useState(false)

    const initialSelect = useRef(null)
    const [initialSelected, setIntitialSelected] = useState(false)

    useEffect(() => {
        if (!initialSelected)
            setTimeout(() => {
                initialSelect.current.focus()
                setIntitialSelected(true)
            }, 0)
    })

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    return (
        <Pane>
            <Dialog
                isShown={props.open}
                confirmLabel='Enregistrer'
                cancelLabel='Annuler'
                isConfirmDisabled={!nameFr && !nameEn && (!index || (index && index === (props.chapter.index + 1).toString()))}
                onConfirm={() => setConfirmation(true)}
                title={'Modifier le chapitre ' + props.chapter.name_fr}
                onCloseComplete={() => props.setOpen(false)}
            >
                <Pane>
                    <TextInputField
                        ref={initialSelect}
                        placeholder={props.chapter.name_fr}
                        onChange={(e) => {
                            if (initialNameFr)
                                setInitialNameFr(false)

                            let val = e.target.value

                            if (!val) 
                                setIsNameFrInvalid(true)
                            else
                                setIsNameFrInvalid(false)
            
                            setNameFr(val)
                        }}
                        isInvalid={!initialNameFr && isNameFrInvalid}
                        label="Nom (FR)*"
                        description="Le nom du chapitre dans la version française de l'app."
                    />

                    <TextInputField
                        placeholder={props.chapter.name}
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                        label="Nom (EN)"
                        description="Le nom du chapitre dans la version anglaise de l'app."
                        hint="Laisser vide pour garder le même titre qu'en français."
                    />

                    <Paragraph fontWeight={600}>Index</Paragraph>
                    <Paragraph fontSize={12} color='rgb(105, 111, 140)' marginBottom={10}>La place du chapitre dans la liste.</Paragraph>
                    <Select onChange={(e) => setIndex(e.target.value)} defaultValue={props.chapter.index + 1} width={75}>
                        {props.chapters.map((c, i) => (
                            <option key={i} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </Select>
                </Pane>

                <Button onClick={() => setDeleteConfirmation(true)} marginTop={50} iconBefore={TrashIcon} intent="danger">
                    Supprimer le chapitre
                </Button>

                <Pane>
                    <Dialog
                        isShown={confirmation}
                        title="Modifier le chapitre ?"
                        onCloseComplete={() => setConfirmation(false)}
                        confirmLabel="Confirmer"
                    >
                        Les modifications seront visibles immédiatement dans l'app. Continuer ?
                    </Dialog>
                </Pane>

                <Pane>
                    <Dialog
                        isShown={deleteConfirmation}
                        title="Supprimer le chapitre ?"
                        onCloseComplete={() => setDeleteConfirmation(false)}
                        confirmLabel="Confirmer"
                    >
                        Le chapitre va être définitivement supprimé. Continuer ?
                    </Dialog>
                </Pane>

            </Dialog>
        </Pane>
    )
}

export default UpdateChapter