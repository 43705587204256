import React, { useState, useEffect } from 'react'
import token from '../../../Token'
import { Combobox, Paragraph, Pane, Text, SelectMenu, Button, EditIcon, AddIcon, Table, TextInput, Spinner } from 'evergreen-ui'
import Page from './Page'
import Quiz from './Page/Quiz'
import NewTheme from './Theme/NewTheme'
import UpdateTheme from './Theme/UpdateTheme'
import NewChapter from './Chapter/NewChapter'
import UpdateChapter from './Chapter/UpdateChapter'
import NewPage from './Page/NewPage'
import NewQuiz from './Page/Quiz/NewQuiz'

const postImage = async (base64) => {
    await fetch('https://api.cloudinary.com/v1_1/hxwbukcjs/image/upload', {
        body: JSON.stringify({
            'file': `data:image/jpg;base64,${base64}`,
            'upload_preset': 'ml_default'
        }),
        headers: {
            'content-type': 'application/json'
        },
        method: 'POST'
    })
}

const getThemes = async () => {
    try {
        let req = await fetch('https://api.noazet.com/v1/themes', {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return
        }

        return res.themes
    } catch (e) {
        console.log(e)
    }
}

const getChapters = async (themeId) => {
    try {
        let req = await fetch('https://api.noazet.com/v1/theme/' + themeId + '/chapters', {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return
        }

        return res.chapters
    } catch (e) {
        console.log(e)
    }
}

const getPages = async (chapterId) => {
    try {
        let req = await fetch('https://api.noazet.com/v1/chapter/' + chapterId + '/screens', {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return
        }

        for (let i = 0; i < res.screens.length; i++) {
            if (res.screens[i].needs_scroll)
                res.screens[i].needs_scroll = 'Oui'
            else
                res.screens[i].needs_scroll = 'Non'

            if (res.screens[i].has_background)
                res.screens[i].has_background = 'Oui'
            else
                res.screens[i].has_background = 'Non'
        }

        return res.screens
    } catch (e) {
        console.log(e)
    }
}

const getQuiz = async (chapterId) => {
    try {
        let req = await fetch('https://api.noazet.com/v1/chapter/' + chapterId + '/questions', {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return
        }

        return res.questions
    } catch (e) {
        console.log(e)
    }
}

const Chapters = (props) => {
    const [themes, setThemes] = useState([])
    const [selectedTheme, setSelectedTheme] = useState({id: ''})
    
    const [chapters, setChapters] = useState([])
    const [selectedChapter, setSelectedChapter] = useState({id: ''})

    const [pages, setPages] = useState([])
    const [quiz, setQuiz] = useState([])

    const [filteredPages, setFilteredPages] = useState(pages[0])
    const [searchingPage, setSearchingPage] = useState(false)

    const [filteredQuiz, setFilteredQuiz] = useState(quiz[0])
    const [searchingQuiz, setSearchingQuiz] = useState(false)

    useEffect(() => {
        const fetchThemes = async () => {
            let themes = await getThemes()
            for (let i = 0; i < themes.length; i++) {
                themes[i].label = themes[i].name_fr
            }
            setSelectedTheme(themes[0])
            setThemes(themes)
        }

        if (!themes.length)
            fetchThemes()

        const fetchChapters = async () => {
            let chapters = await getChapters(selectedTheme.id)
            for (let i = 0; i < chapters.length; i++) {
                chapters[i].label = chapters[i].name_fr
            }
            setSelectedChapter(chapters[0])
            setChapters(chapters)
        }

        if (themes.length && !chapters.length)
            fetchChapters()

        const fetchPages = async () => {
            let pages = await getPages(selectedChapter.id)
            for (let i = 0; i < pages.length; i++) {
                pages[i].label = pages[i].name_fr
            }
            setPages(pages)
        }

        if (themes.length && chapters.length && !quiz.length)
            fetchPages()

        const fetchQuiz = async () => {
            let quiz = await getQuiz(selectedChapter.id)
            for (let i = 0; i < quiz.length; i++) {
                quiz[i].label = quiz[i].name_fr
            }
            setQuiz(quiz)
        }

        if (themes.length && chapters.length && !quiz.length)
            fetchQuiz()

    }, [themes.length, chapters.length, pages.length, quiz.length, selectedChapter.id, selectedTheme ? selectedTheme.id : null])

    const [finalPages, setFinalPages] = useState([])
    const [finalQuiz, setFinalQuiz] = useState([])

    useEffect(() => {
        if (searchingPage)
            setFinalPages(filteredPages)
        else
            setFinalPages(pages)

        if (searchingQuiz)
            setFinalQuiz(filteredQuiz)
        else
            setFinalQuiz(quiz)
    }, [filteredPages, searchingPage, pages, filteredQuiz, searchingQuiz, quiz])

    const [pageOpen, setPageOpen] = useState({})
    const [quizOpen, setQuizOpen] = useState({})

    const [newThemeOpen, setNewThemeOpen] = useState(false)
    const [editThemeOpen, setEditThemeOpen] = useState(false)

    const [newChapterOpen, setNewChapterOpen] = useState(false)
    const [editChapterOpen, setEditChapterOpen] = useState(false)

    const [newPageOpen, setNewPageOpen] = useState(false)
    const [newQuizOpen, setNewQuizOpen] = useState(false)

    return (
        themes.length ?
            <>
                <Paragraph marginTop={25}><b>Thème</b></Paragraph>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{maxWidth: 250}}>
                        <Combobox
                            marginBottom={10} marginTop={10}
                            initialSelectedItem={themes.length ? themes[0] : {}}
                            items={themes}
                            itemToString={item => (item ? item.label : '')}
                            onChange={async (theme) => {
                                setChapters([])
                                setPages([])
                                setSelectedTheme(theme)
                                let chapters = await getChapters(theme.id)
                                for (let i = 0; i < chapters.length; i++) {
                                    chapters[i].label = chapters[i].name_fr
                                }
                                let pages = await getPages(chapters[0].id)
                                setSelectedChapter(chapters[0])
                                setChapters(chapters)
                                setPages(pages)
                            }}
                        />
                    </div>
                    <Button onClick={(e) => setNewThemeOpen(true)} marginLeft={25} iconBefore={AddIcon}>
                        Nouveau thème
                    </Button>

                    {newThemeOpen ? <NewTheme postImage={postImage} themes={themes} setThemes={setThemes} open={newThemeOpen} setOpen={setNewThemeOpen}/> : <></>}

                    {editThemeOpen ? <UpdateTheme postImage={postImage} theme={selectedTheme} themes={themes} setThemes={setThemes} open={editThemeOpen} setOpen={setEditThemeOpen}/> : <></>}
                </div>

                {!chapters.length ? <Spinner marginTop={25} marginBottom={25} marginLeft='calc(50vw - 191px)'/> : <></>}

                {chapters.length ?
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{height: 60, width: 60, backgroundColor: '#277C9E', borderRadius: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 25}}>
                                <img style={{height: selectedTheme.iconHeight, width: selectedTheme.iconWidth}} alt='Theme icon' src={selectedTheme.iconPath}/>
                            </div>
                            
                            <Button onClick={(e) => setEditThemeOpen(true)} iconBefore={EditIcon}>
                                Modifier le thème
                            </Button>
                        </div>

                        <Paragraph marginTop={25}><b>Chapitre</b></Paragraph>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{maxWidth: 250}}>
                                <Combobox
                                    marginTop={10} marginBottom={20}
                                    initialSelectedItem={chapters.length ? chapters[0] : {}}
                                    items={chapters}
                                    itemToString={item => (item ? item.label : '')}
                                    onChange={async (chapter) => {
                                        let pages = await getPages(chapter.id)
                                        setSelectedChapter(chapter)
                                        setPages(pages)
                                    }}
                                />
                            </div>
                            <Button onClick={() => {
                                setNewChapterOpen(true)
                            }} marginLeft={25} position='relative' top={-5} iconBefore={AddIcon}>
                                Nouveau chapitre
                            </Button>

                            {newChapterOpen ? <NewChapter postImage={postImage} theme={selectedTheme} chapters={chapters} setChapters={setChapters} open={newChapterOpen} setOpen={setNewChapterOpen}/> : <></>}

                            {editChapterOpen ? <UpdateChapter postImage={postImage} chapter={selectedChapter} chapters={chapters} setChapters={setChapters} open={editChapterOpen} setOpen={setEditChapterOpen}/> : <></>}

                        </div>
                        <Button onClick={(e) => setEditChapterOpen(true)} iconBefore={EditIcon}>
                            Modifier le chapitre
                        </Button>

                        <Paragraph marginTop={25} marginBottom={10}><b>Pages</b></Paragraph>

                        <TextInput onChange={(e) => {
                            let t = e.target.value

                            if (t)
                                setSearchingPage(true)
                            else
                                setSearchingPage(false)

                            if (pages.length) {
                                let filteredPages = []
                                
                                for (let i = 0; i < pages.length; i++) {
                                    if (pages[i].id.toString().includes(t) || (pages[i].title_fr ? pages[i].title_fr.includes(t) : false) || (pages[i].title ? pages[i].title.includes(t) : false) || (pages[i].content ? pages[i].content.includes(t) : false) || (pages[i].content_fr ? pages[i].content_fr.includes(t) : false)) {
                                        filteredPages.push(pages[i])
                                    }
                                }

                                setFilteredPages(filteredPages)
                            }
                        }} name="search" placeholder="Rechercher..."/>

                        <Button onClick={() => setNewPageOpen(true)} marginLeft={25} iconBefore={AddIcon}>
                            Nouvelle page
                        </Button>

                        {newPageOpen ? <NewPage postImage={postImage} chapter={selectedChapter} pages={pages} setPages={setPages} open={newPageOpen} setOpen={setNewPageOpen}/> : <></>}
                        {newQuizOpen ? <NewQuiz postImage={postImage} chapter={selectedChapter} quiz={quiz} setQuiz={setQuiz} open={newQuizOpen} setOpen={setNewQuizOpen}/> : <></>}

                        {finalPages.length ? <Table marginTop={25}>
                            <Table.Head>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>ID</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Titre</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Titre EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Contenu</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Contenu EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Scrollable</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Background bulles</Table.TextHeaderCell>
                            </Table.Head>

                            {!finalPages.length && !searchingPage ? <Spinner marginTop={25} marginBottom={25} marginLeft='calc(50vw - 191px)'/> : <></>}
                            
                            <Table.Body >
                                {finalPages.map((page, i) => (
                                    <React.Fragment key={i}>
                                        <Table.Row key={page.id} isSelectable onSelect={() => setPageOpen({
                                            [i]: true
                                        })}>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)' isNumber>{page.id}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{page.title_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{page.title}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{page.content_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{page.content}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{page.needs_scroll}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{page.has_background}</Table.TextCell>
                                        </Table.Row>

                                        {pageOpen[i] ? <Page pages={pages} setPages={setPages} page={page} open={pageOpen} setOpen={setPageOpen} i={i}/> : <></>}
                                    </React.Fragment>
                                ))}
                            </Table.Body>
                        </Table> : <></>}
                        
                        <br/>

                        <Paragraph marginTop={25} marginBottom={10}><b>Quiz</b></Paragraph>

                        <TextInput onChange={(e) => {
                            let t = e.target.value

                            if (t)
                                setSearchingQuiz(true)
                            else
                                setSearchingQuiz(false)

                            if (quiz.length) {
                                let filteredQuiz = []
                                
                                for (let i = 0; i < quiz.length; i++) {
                                    if (quiz[i].id.toString().includes(t) || quiz[i].question.includes(t) || quiz[i].question_fr.includes(t) || quiz[i].first_answer.includes(t) || quiz[i].first_answer_fr.includes(t) || quiz[i].second_answer.includes(t) || quiz[i].second_answer_fr.includes(t) || quiz[i].third_answer.includes(t) || quiz[i].third_answer_fr.includes(t) || quiz[i].fourth_answer.includes(t) || quiz[i].fourth_answer_fr.includes(t) || (quiz[i].fifth_answer && (quiz[i].fifth_answer.includes(t) || quiz[i].fifth_answer_fr.includes(t))) || (quiz[i].sixth_answer && (quiz[i].sixth_answer.includes(t) || quiz[i].sixth_answer_fr.includes(t) || quiz[i].answer_description.includes(t))) || quiz[i].answer_description_fr.includes(t) || (quiz[i].answer_description2 && (quiz[i].answer_description2.includes(t) || quiz[i].answer_description2_fr.includes(t))) || (quiz[i].answer_description3 && (quiz[i].answer_description3.includes(t) || quiz[i].answer_description3_fr.includes(t)))) {
                                        filteredQuiz.push(quiz[i])
                                    }
                                }

                                setFilteredQuiz(filteredQuiz)
                            }
                        }} name="search" placeholder="Rechercher..."/>

                        <Button onClick={() => setNewQuizOpen(true)} marginLeft={25} iconBefore={AddIcon}>
                            Nouveau quiz
                        </Button>

                        {finalQuiz.length ? <Table marginTop={25}>
                            <Table.Head>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>ID</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Question FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Question EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 1 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 1 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 2 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 2 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 3 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 3 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 4 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 4 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 5 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 5 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 6 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse 6 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Réponse(s)</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Explication réponse 1 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Explication réponse 1 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Explication réponse 2 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Explication réponse 2 EN</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Explication réponse 3 FR</Table.TextHeaderCell>
                                <Table.TextHeaderCell maxWidth='calc((100vw - 191px) / 7)'>Explication réponse 3 EN</Table.TextHeaderCell>
                            </Table.Head>

                            {!finalQuiz.length && !searchingQuiz ? <Spinner marginTop={25} marginBottom={25} marginLeft='calc(50vw - 191px)'/> : <></>}
                            
                            <Table.Body >
                                {finalQuiz.map((question, i) => (
                                    <React.Fragment key={i}>
                                        <Table.Row key={question.id} isSelectable onSelect={() => setQuizOpen({
                                            [i]: true
                                        })}>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)' isNumber>{question.id}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.question_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.question}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.first_answer_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.first_answer}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.second_answer_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.second_answer}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.third_answer_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.third_answer}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.fourth_answer_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.fourth_answer}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.fifth_answer_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.fifth_answer}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.sixth_answer_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.sixth_answer}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answers}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answer_description_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answer_description}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answer_description2_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answer_description2}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answer_description3_fr}</Table.TextCell>
                                            <Table.TextCell maxWidth='calc((100vw - 191px) / 7)'>{question.answer_description3}</Table.TextCell>
                                        </Table.Row>

                                        {quizOpen[i] ? <Quiz quiz={quiz} setQuiz={setQuiz} question={question} open={quizOpen} setOpen={setQuizOpen} i={i}/> : <></>}
                                    </React.Fragment>
                                ))}
                            </Table.Body>
                        </Table> : <></>}
                        
                        <br/>
                    </>
                : <></>}
            </>
        : <></>
    )
}

export default Chapters
