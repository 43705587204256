import { useState, useEffect } from 'react'
import token from '../../../Token'
import avatar from '../../../assets/images/avatar.png'
import { Pane, Dialog, Paragraph, Button, EditIcon, TrashIcon } from 'evergreen-ui'

const getUsername = async (id) => {
    try {
        let req = await fetch('https://api.noazet.com/v1/user/' + id, {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return null
        }

        return res.users[0].username

    } catch (e) {
        console.log(e)
    }
}

const getTrialCount = async (id) => {
    try {
        let req = await fetch('https://api.noazet.com/v1/trials/' + id, {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return null
        }

        return res.trial.count

    } catch (e) {
        console.log(e)
    }
}

const getStars = async (id) => {
    try {
        let req = await fetch('https://api.noazet.com/v1/stars/' + id, {
            headers: {
                'x-access-token': token,
            },
        })

        let res = await req.json()
        if (res.error) {
            return null
        }

        return res.stars

    } catch (e) {
        console.log(e)
    }
}

// get favorites + achievements + stars per theme + reports

const User = (props) => {
    const [dataFilled, setDataFilled] = useState(false)

    useEffect(() => {
        const fillData = async () => {
            let users = props.users

            if (users[props.i].referer !== '/') {
                users[props.i].referer = await getUsername(users[props.i].referer)
            }

            users[props.i].hebdoChapsLeft = await getTrialCount(users[props.i].id)
            if (!users[props.i].hebdoChapsLeft)
                users[props.i].hebdoChapsLeft = 10

            users[props.i].stars = await getStars(users[props.i].id)

            props.setUsers(users)
            setDataFilled(true)
        }
        
        if (!dataFilled)
            fillData()
    })

    return (
        <Pane>
            <Dialog
                isShown={props.open[props.i]}
                hasFooter={false}
                title={props.user.username}
                onCloseComplete={() => props.setOpen({[props.i] : false})}
            >
                
                <Pane>
                    <Button marginBottom={25} marginRight={25} iconBefore={EditIcon}>
                        Modifier l'utilisateur
                    </Button>
                    <Button marginBottom={25} iconBefore={TrashIcon} intent="danger">
                        Supprimer l'utilisateur
                    </Button>

                    {props.user.imagePath ? <img style={{height: 125, width: 125, marginBottom: 25}} alt='avatar' src={props.user.imagePath}/> : <img style={{height: 125, width: 125, marginBottom: 25}} alt='avatar' src={avatar}/>}
                    
                    
                    <Paragraph><b>ID</b> : {props.user.id}</Paragraph>
                    <Paragraph><b>Nom d'utilisateur</b> : {props.user.username}</Paragraph>
                    <Paragraph><b>Adresse e-mail</b> : {props.user.email}</Paragraph>
                    <Paragraph><b>Objectif</b> : {props.user.objective}</Paragraph>
                    <Paragraph><b>Expérience</b> : {props.user.experience}</Paragraph>
                    <Paragraph><b>Balance (NZT)</b> : {props.user.balance}</Paragraph>
                    <Paragraph><b>Date d'inscription</b> : {props.user.createdAt}</Paragraph>
                    <Paragraph><b>Dernière activité</b> : {props.user.updatedAt}</Paragraph>
                    <Paragraph><b>Adresse e-mail vérifiée</b> : {props.user.isEmailVerified}</Paragraph>
                    <Paragraph><b>Dernière adresse IP</b> : {props.user.lastIp}</Paragraph>
                    <Paragraph><b>Date de fin d'abonnement</b> : {props.user.premiumEndDate}</Paragraph>
                    <Paragraph><b>XP</b> : {props.user.xp}</Paragraph>
                    <Paragraph><b>Dernier streak de connexion</b> : {props.user.streak}</Paragraph>
                    <Paragraph><b>Temps de jeu</b> : {props.user.gameTime}</Paragraph>
                    <Paragraph><b>Streaks de 7 jours</b> : {props.user.streak7_count}</Paragraph>
                    <Paragraph><b>Parrain</b> : {props.user.referer}</Paragraph>
                    <Paragraph><b>Nombre de filleuls</b> : {props.user.referees}</Paragraph>
                    <Paragraph><b>Objectif</b> : {props.user.streak_record}</Paragraph>
                    <Paragraph><b>Chapitres gratuits restants</b> : {props.user.freeChapsLeft}</Paragraph>
                    <Paragraph><b>Code de parrainage</b> : {props.user.refCode}</Paragraph>
                    <Paragraph><b>Nombre de chapitres hebdomadaires restants</b> : {props.user.hebdoChapsLeft}</Paragraph>
                    <Paragraph><b>Nombre d'étoiles</b> : {props.user.stars}</Paragraph>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default User