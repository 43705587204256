import { useState, useEffect, useRef } from 'react'
import token from '../../../../Token'
import { Pane, Dialog, TextInputField, Select, Paragraph } from 'evergreen-ui'

const NewChapter = (props) => {
    const [nameFr, setNameFr] = useState(null)
    const [nameEn, setNameEn] = useState(null)

    const [isNameFrInvalid, setIsNameFrInvalid] = useState(true)
    const [initialNameFr, setInitialNameFr] = useState(true)

    const [confirmation, setConfirmation] = useState(false)

    const initialSelect = useRef(null)
    const [initialSelected, setIntitialSelected] = useState(false)

    useEffect(() => {
        if (!initialSelected)
            setTimeout(() => {
                initialSelect.current.focus()
                setIntitialSelected(true)
            }, 0)
    })

    return (
        <Pane>
            <Dialog
                isShown={props.open}
                confirmLabel='Créer'
                cancelLabel='Annuler'
                isConfirmDisabled={isNameFrInvalid}
                onConfirm={() => setConfirmation(true)}
                title={'Nouveau chapitre dans le thème ' +  props.theme.name_fr}
                onCloseComplete={() => props.setOpen(false)}
            >
                <Pane>
                    <TextInputField
                        ref={initialSelect}
                        onChange={(e) => {
                            if (initialNameFr)
                                setInitialNameFr(false)

                            let val = e.target.value

                            if (!val) 
                                setIsNameFrInvalid(true)
                            else
                                setIsNameFrInvalid(false)
            
                            setNameFr(val)
                        }}
                        isInvalid={!initialNameFr && isNameFrInvalid}
                        label="Nom (FR)*"
                        description="Le nom du chapitre dans la version française de l'app."
                    />

                    <TextInputField
                        onChange={(e) => {
                            setNameEn(e.target.value)
                        }}
                        label="Nom (EN)"
                        description="Le nom du chapitre dans la version anglaise de l'app."
                        hint="Laisser vide pour garder le même titre qu'en français."
                    />

                    <Paragraph fontWeight={600}>Index</Paragraph>
                    <Paragraph fontSize={12} color='rgb(105, 111, 140)' marginBottom={10}>La place du nouveau chapitre dans la liste.</Paragraph>
                    <Select defaultValue={props.chapters.length + 1} width={75}>
                        {[...props.chapters, {}].map((c, i) => (
                            <option key={i} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </Select>

                    <Pane>
                        <Dialog
                            isShown={confirmation}
                            title="Créer le chapitre ?"
                            onCloseComplete={() => setConfirmation(false)}
                            confirmLabel="Confirmer"
                        >
                            Le chapitre va être visible immédiatement dans l'app. Continuer ?
                        </Dialog>
                    </Pane>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default NewChapter