import { useState, useEffect, useCallback, useRef } from 'react'
import token from '../../../../../Token'
import { Pane, Dialog, TextInputField, Select, Paragraph, FileUploader, FileCard, Switch } from 'evergreen-ui'

const NewQuiz = (props) => {
    const [questionFr, setQuestionFr] = useState(null)
    const [questionEn, setQuestionEn] = useState(null)

    const [answer1, setAnswer1] = useState(null)
    const [answer1Fr, setAnswer1Fr] = useState(null)
    const [answer2, setAnswer2] = useState(null)
    const [answer2Fr, setAnswer2Fr] = useState(null)
    const [answer3, setAnswer3] = useState(null)
    const [answer3Fr, setAnswer3Fr] = useState(null)
    const [answer4, setAnswer4] = useState(null)
    const [answer4Fr, setAnswer4Fr] = useState(null)
    const [answer5, setAnswer5] = useState(null)
    const [answer5Fr, setAnswer5Fr] = useState(null)
    const [answer6, setAnswer6] = useState(null)
    const [answer6Fr, setAnswer6Fr] = useState(null)

    const [answerDescription1, setAnswerDescription1] = useState(null)
    const [answerDescription1Fr, setAnswerDescription1Fr] = useState(null)
    const [answerDescription2, setAnswerDescription2] = useState(null)
    const [answerDescription2Fr, setAnswerDescription2Fr] = useState(null)
    const [answerDescription3, setAnswerDescription3] = useState(null)
    const [answerDescription3Fr, setAnswerDescription3Fr] = useState(null)

    const [answers, setAnswers] = useState(null)

    const [initialNameFr, setInitialNameFr] = useState(true)

    const [files, setFiles] = useState([])
    const [fileRejections, setFileRejections] = useState([])
    const handleChange = useCallback((files) => setFiles([files[0]]), [])
    const handleRejected = useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove = useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])

    const [confirmation, setConfirmation] = useState(false)

    const initialSelect = useRef(null)
    const [initialSelected, setIntitialSelected] = useState(false)

    useEffect(() => {
        if (!initialSelected)
            setTimeout(() => {
                initialSelect.current.focus()
                setIntitialSelected(true)
            }, 0)
    })

    const [isQcm, setIsQcm] = useState(false)

    return (
        <Pane>
            <Dialog
                isShown={props.open}
                confirmLabel='Créer'
                cancelLabel='Annuler'
                isConfirmDisabled={!questionFr || !questionEn || (!answer1 || !answer1Fr || !answer2 || !answer2Fr || !answer3 || !answer3Fr || !answer4 || !answer4Fr || !answerDescription1 || !answerDescription1Fr) || (isQcm && (!answer5 || !answer5Fr || !answer6 || !answer6Fr || answerDescription2 || answerDescription2Fr || answerDescription3 || answerDescription3Fr))}
                onConfirm={() => setConfirmation(true)}
                title={'Nouveau quiz dans le chapitre ' + props.chapter.name_fr} 
                onCloseComplete={() => props.setOpen(false)}
            >
                <Pane>
                    <Paragraph fontWeight={600} marginBottom={10}>QCM ?</Paragraph>
                    <Switch marginBottom={25} height={20} checked={isQcm} onChange={(e) => setIsQcm(e.target.checked)}/>

                    <TextInputField
                        ref={initialSelect}
                        onChange={(e) => {
                            if (initialNameFr)
                                setInitialNameFr(false)

                            setQuestionFr(e.target.value)
                        }}
                        label="Question (FR)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setQuestionEn(e.target.value)
                        }}
                        label="Question (EN)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer1Fr(e.target.value)
                        }}
                        label="Réponse 1 (FR)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer1(e.target.value)
                        }}
                        label="Réponse 1 (EN)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer2Fr(e.target.value)
                        }}
                        label="Réponse 2 (FR)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer2(e.target.value)
                        }}
                        label="Réponse 2 (EN)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer3Fr(e.target.value)
                        }}
                        label="Réponse 3 (FR)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer3(e.target.value)
                        }}
                        label="Réponse 3 (EN)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer4Fr(e.target.value)
                        }}
                        label="Réponse 4 (FR)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswer4(e.target.value)
                        }}
                        label="Réponse 4 (EN)*"
                    />

                    {isQcm ?
                        <>
                            <TextInputField
                                onChange={(e) => {
                                    setAnswer5Fr(e.target.value)
                                }}
                                label="Réponse 5 (FR)*"
                            />

                            <TextInputField
                                onChange={(e) => {
                                    setAnswer5(e.target.value)
                                }}
                                label="Réponse 5 (EN)*"
                            />

                            <TextInputField
                                onChange={(e) => {
                                    setAnswer6Fr(e.target.value)
                                }}
                                label="Réponse 6 (FR)*"
                            />

                            <TextInputField
                                onChange={(e) => {
                                    setAnswer6(e.target.value)
                                }}
                                label="Réponse 6 (EN)*"
                            />
                        </>
                    : <></>}

                    <TextInputField
                        onChange={(e) => {
                            setAnswers(e.target.value)
                        }}
                        label="Bonnes réponses*"
                        description="Séparer les nombres par des virgules."
                        hint="Ex: 1,2,6"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswerDescription1Fr(e.target.value)
                        }}
                        label="Explication réponse 1 (FR)*"
                    />

                    <TextInputField
                        onChange={(e) => {
                            setAnswerDescription1(e.target.value)
                        }}
                        label="Explication réponse 1 (EN)*"
                    />

                    {isQcm ?
                        <>
                            <TextInputField
                                onChange={(e) => {
                                    setAnswerDescription2Fr(e.target.value)
                                }}
                                label="Explication réponse 2 (FR)*"
                            />

                            <TextInputField
                                onChange={(e) => {
                                    setAnswerDescription2(e.target.value)
                                }}
                                label="Explication réponse 2 (EN)*"
                            />

                            <TextInputField
                                onChange={(e) => {
                                    setAnswerDescription3Fr(e.target.value)
                                }}
                                label="Explication réponse 3 (FR)*"
                            />

                            <TextInputField
                                onChange={(e) => {
                                    setAnswerDescription3(e.target.value)
                                }}
                                label="Explication réponse 3 (EN)*"
                            />
                        </>
                    : <></>}

                    <Pane marginTop={25} maxWidth={654}>
                        <FileUploader
                            label="Illustration"
                            description="Taille maximale 30 MB. Dimensions max recommandées : 750 x 750px."
                            maxSizeInBytes={30 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChange}
                            onRejected={handleRejected}
                            renderFile={(file) => {
                                const { name, size, type } = file
                                const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                const { message } = fileRejection || {}
                                return (
                                    <FileCard
                                        key={name}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemove}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                    />
                                )
                            }}
                            values={files}
                        />
                    </Pane>
                
                    <Pane>
                        <Dialog
                            isShown={confirmation}
                            title="Créer le quiz ?"
                            onCloseComplete={() => setConfirmation(false)}
                            confirmLabel="Confirmer"
                        >
                            Le quiz va être visible immédiatement dans l'app. Continuer ?
                        </Dialog>
                    </Pane>
                </Pane>
            </Dialog>
        </Pane>
    )
}

export default NewQuiz