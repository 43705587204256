import React, { useState } from 'react'
import { Button, TextInput, Text } from 'evergreen-ui'
import './index.css'
import logo from '../../assets/images/logo-noazet.png'
import token from '../../Token'

const Login = (props) => {
    const [password, setPassword] = useState('')
    const [fa, setFa] = useState('')
    const [error, setError] = useState('')

    const login = async (e) => {
        setLoading(true)
        e.preventDefault()
        if (!props.username) {
            setError("Nom d'utilisateur vide.")
            setLoading(false)
            return
        }
        if (!password) {
            setError("Mot de passe vide.")
            setLoading(false)
            return
        }
        if (!fa) {
            setError("2FA vide.")
            setLoading(false)
            return
        }
        if (!/^\d+$/.test(fa)) {
            setError("2FA invalide.")
            setLoading(false)
            return
        }
        setError('')  
        try {
            let req = await fetch('https://api.noazet.com/v1/admin', {
                headers: {
                    'x-access-token': token,
                },
                method: 'POST',
                body: JSON.stringify({
                    username: props.username,
                    password,
                    two_fa_code: parseInt(fa),
                })
            })

            let res = await req.json()
            if (res.error) {
                setError(res.error + ".")
                setLoading(false)
                return
            }
            props.setCookie('noazet-admin', props.username)

        } catch (e) {
            console.log(e)
            setError("Erreur serveur.")
        }
        setLoading(false)
    }

    const [loading, setLoading] = useState(false)

    return (
        <>
            <div className="box">
                <div className="d-flex container h-100 align-items-center justify-content-center">
                    <form action="" className="login-bar">
                        <div className="row pb-5">
                            <div className="col-12 justify-content-center d-flex">
                                <img src={logo} className="App-logo" alt="logo" />
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12">
                                <TextInput value={props.username} placeholder="Nom d'utilisateur" type="text" className="username" onChange={(e) => props.setUsername(e.target.value)} />
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-12">
                                <TextInput value={password} placeholder="Mot de passe" type="password" className="password" onChange={(event) => setPassword(event.target.value)} />
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12">
                                <TextInput value={fa} placeholder="2FA" type="text" className="2fa" onChange={(event) => setFa(event.target.value)} />
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-12">
                                <Button isLoading={loading} type="submit" onClick={login} className="text-center w-100 login-button">Connexion</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Text className="error-message">
                                    {error}
                                </Text>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Login
