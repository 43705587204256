import React, { useState } from 'react'
import { Pane, Paragraph } from 'evergreen-ui'
// import logo from '../../assets/images/logo-noazet.png'
// import token from '../../Token'
import Nav from './Nav'
import Users from './Users'
import Chapters from './Chapters'
import Glossary from './Glossary'

const Home = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tabs] = useState(['Utilisateurs', 'Chapitres', 'Glossaire', 'Analytics', 'Notifications in-app'])

    return (
        <div style={{display: 'flex', height: '100vh'}}>
            <Nav username={props.username} tabs={tabs} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} removeCookie={props.removeCookie}/>
            <Pane display="flex" marginLeft={175} minWidth='calc(100vw - 175px)'>
                <Pane padding={16} background="tint1" flex="1">
                    {tabs.map((tab, index) => (
                        <Pane
                            key={tab}
                            id={`panel-${tab}`}
                            role="tabpanel"
                            aria-labelledby={tab}
                            aria-hidden={index !== selectedIndex}
                            display={index === selectedIndex ? 'block' : 'none'}
                        >
                            <Paragraph>{tab}</Paragraph>
                            {selectedIndex === 0 ? <Users/> : <></>}
                            {selectedIndex === 1 ? <Chapters/> : <></>}
                        </Pane>
                    ))}
                </Pane>
            </Pane>
        </div>
    )
}
export default Home
