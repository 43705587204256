import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useCookies } from 'react-cookie'
import { Routes, Route, Outlet } from 'react-router-dom'
import Login from './components/Login'
import Home from './components/Home'
import NotFound from './components/404'

const App = () => {
  const [username, setUsername] = useState('')

  const [cookies, setCookie, removeCookie] = useCookies(['noazet-admin'])

  useEffect(() => {
    if (!username) {
      if (cookies['noazet-admin'])
        setUsername(cookies['noazet-admin'])
    }
  }, [username, cookies])

  return (
    <Routes>
      <Route path="/" element={<Outlet/>}>
        <Route index={cookies['noazet-admin'] ? true : false} path="/" element={<Home username={username} removeCookie={removeCookie}/>}/>
        <Route index={cookies['noazet-admin'] ? false : true} path="/" element={<Login username={username} setUsername={setUsername} setCookie={setCookie}/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}

export default App